<template>
  <div class="mini-tag" :class="`bg-${bgcolor}`">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'MiniTag',

  props: {
    text: {
      type: String,
      required: true,
    },

    id: {
      type: [Number, String],
      default: null,
    },

    color: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bgcolor: 'material-color-18',
    }
  },
  created() {
    if (this.color) {
      this.bgcolor = this.color
    }
    if (this.id) {
      if (typeof this.id === 'string') {
        this.bgcolor = `material-color-${this.id.length % 19}`
      } else {
        this.bgcolor = `material-color-${this.id % 19}`
      }
    }
  },
}
</script>

<style lang="scss" scoped>
/* MINI TAG */

$mini-tag-dot-size: ($gutter-eighth / 2 * 3);
$mini-tag-horizontal-padding: $mini-tag-dot-size;
$mini-tag-vertical-padding: $gutter-eighth;

.mini-tag {
  position: relative;
  display: inline-block;
  padding: $mini-tag-vertical-padding $mini-tag-horizontal-padding;
  padding-left: 2 * $mini-tag-horizontal-padding + $mini-tag-dot-size;
  border-radius: $border-radius-base;
  font-size: $font-size-small;
  line-height: 1;
  color: white;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: $mini-tag-horizontal-padding;
    transform: translateY(-50%);
    border-radius: 50%;
    @include size($mini-tag-dot-size);
    background-color: currentColor;
  }
}
</style>
