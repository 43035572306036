<template>
  <div class="view view--user">
    <div class="view-header">
      <div class="view-header__header">
        <Pagination />
        <Btn
          class="action-btn"
          icon="plus"
          color="primary"
          text="Ajouter un utilisateur"
          :to="{ name: 'userAdd' }"
        />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title">Liste des utilisateurs</h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section--list">
        <Search
          v-model="search"
          searchEndpoint="utilisateur"
          searchPlaceholder="Rechercher un utilisateur"
        >
          <template v-slot:suggestions="{ item }">
            <router-link :to="{ name: 'user', params: { userid: item.id } }">
              {{ item.prenom }}
              {{ item.nom }}
            </router-link>
          </template>

          <SelectExtended
            id="entite"
            v-model="search.filters.entite.values"
            multiple
            search
            label="Entité"
            optionKey="id"
            optionValue="nom"
            apiEndpoint="entite"
          />

          <SelectExtended
            id="profil"
            v-model="search.filters.profil.values"
            search
            multiple
            label="Profil"
            optionKey="id"
            optionValue="designation"
            apiEndpoint="profil"
          />

          <Radio
            v-model="search.filters.actif.values"
            id="actif"
            :items="[
              { label: 'oui', value: true },
              { label: 'non', value: false },
            ]"
            label="Actif"
            inputStyle="inline"
          />
        </Search>

        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
          :target="list.target"
        >
          <template v-slot:group-actions="{ selectedItems }">
            <Btn
              v-if="
                this.helperService.userHasPermission(
                  `utilisateur_remove_full,utilisateur_remove_entite`,
                  'OR'
                )
              "
              text="Supprimer"
              icon="trash-alt"
              color="default"
              @click="openModal('deleteItems', selectedItems)"
            />
          </template>

          <template v-slot:actif="{ item }">
            <span v-if="item.actif">Actif</span>
            <span v-else>Inactif</span>
          </template>

          <template v-slot:prenom="{ item }">
            <UserID
              :userNameFirstName="item.prenom"
              :userNameLastName="item.nom"
              :userAvatarImage="item.avatar"
              userAvatarSize="small"
              :userAvatarColor="item.couleur"
              :userDisabled="!item.actif"
            />
          </template>

          <template v-slot:profils="{ item }">
            <template v-if="item.profils.length > 0">
              <div class="tags">
                <MiniTag
                  v-for="profil in item.profils"
                  :key="profil.id"
                  :id="profil.uid"
                  :text="profil.designation"
                />
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:actions="{ item }">
            <!-- <Btn icon="kefta-dots" default round grow/> -->
            <ToolDropdown>
              <MenuItem
                v-if="
                  this.helperService.userHasPermission(
                    'utilisateur_show_full,utilisateur_show_entite',
                    'OR'
                  )
                "
                :item-data="{
                  label: 'Consulter',
                  iconName: 'visibility',
                  route: { name: 'user', params: { userid: item.id } },
                }"
              />
              <MenuItem
                v-if="
                  this.helperService.userHasPermission(
                    `utilisateur_remove_full,utilisateur_remove_entite,
                  utilisateur_edit_full,utilisateur_edit_entite`,
                    'OR'
                  )
                "
                :item-data="{
                  label: 'Éditer/supprimer',
                  iconName: 'create',
                  route: { name: 'userEdit', params: { userid: item.id } },
                }"
              />
              <MenuItem
                v-if="
                  !(item.id === $store.state.auth.user.data.id) &&
                  item.actif &&
                  this.helperService.userHasPermission(
                    `utilisateur_edit_full,utilisateur_edit_entite`,
                    'OR'
                  )
                "
                :item-data="{
                  label: 'Désactiver',
                  iconName: 'user-times',
                }"
                @click="openModal('disableUser', item)"
              />
              <MenuItem
                v-if="
                  this.helperService.userHasPermission(
                    `utilisateur_edit_full,utilisateur_edit_entite`,
                    'OR'
                  )
                "
                :item-data="{
                  label: 'Ré-initialiser le mot de passe',
                  iconName: 'key',
                }"
                @click="openModal('reinitUserPwd', item)"
              />
              <MenuItem
                v-if="this.helperService.userHasPermission(`log_list`)"
                :item-data="{
                  label: 'Voir les logs',
                  route: {
                    name: 'ressourceLogs',
                    params: {
                      ressource: 'utilisateur',
                      rid: 'user',
                      ressourceid: item.id,
                      from: 'users',
                      title: `Consulter les logs de ${item.prenom} ${item.nom}`,
                    },
                  },
                  iconName: 'clipboard-list',
                }"
              />
              <MenuItem
                v-if="this.helperService.userHasPermission(`profil_show`)"
                :item-data="{
                  label: 'Droits',
                  route: {
                    name: 'userPermissions',
                    params: { userid: item.id },
                  },
                  iconName: 'lock',
                }"
              />
            </ToolDropdown>
          </template>
        </List>

        <!-- Modals modalDeleteUser-->
        <Modal
          title="Supprimer des utilisateur&nbsp;?"
          :active="modal.deleteItems"
          :data="modalData"
          @modal-close="modal.deleteItems = false"
        >
          <template v-slot:modal-body="{ data }">
            <p>
              Voulez vous vraiment supprimer
              {{ data.length }} utiisateurs&nbsp;?
            </p>
          </template>
          <template v-slot:modal-footer="{ data }">
            <Btn text="Annuler" @click="modal.deleteItems = false" />
            <Btn
              text="Supprimer"
              @click="deleteItems(data)"
              icon="trash-alt"
              color="primary"
            />
          </template>
        </Modal>

        <!-- Modals modalReinitUserPwd-->
        <Modal
          title="Ré-initialiser le mot de passe"
          :active="modal.reinitUserPwd"
          :data="modalData"
          @modal-close="modal.reinitUserPwd = false"
        >
          <template v-slot:modal-body="{ data }">
            <p>
              Voulez vous vraiment envoyer une demande ré-initialisation de mot
              de passe à
              <b>{{ data.prenom }} {{ data.nom }}</b
              >&nbsp;?
            </p>
          </template>
          <template v-slot:modal-footer>
            <Btn text="Annuler" @click="modal.reinitUserPwd = false" />
            <Btn
              text="Envoyer"
              @click="reinitPwd()"
              icon="key"
              color="primary"
            />
          </template>
        </Modal>

        <!-- Modals modalDisableUser-->
        <Modal
          title="Désactiver"
          :active="modal.disableUser"
          :data="modalData"
          @modal-close="modal.disableUser = false"
        >
          <template v-slot:modal-body="{ data }">
            <p>
              Voulez vous vraiment désactiver
              <b>{{ data.prenom }} {{ data.nom }}</b
              >&nbsp;?
            </p>
          </template>
          <template v-slot:modal-footer>
            <Btn text="Annuler" @click="modal.disableUser = false" />
            <Btn
              text="Désactiver"
              @click="disableUser()"
              icon="user-times"
              color="primary"
            />
          </template>
        </Modal>
      </Section>
    </div>
  </div>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Pagination from '@/components/list/Pagination.vue'
import Search from '@/components/list/Search.vue'
import List from '@/components/list/List.vue'
import UserID from '@/components/user/UserID.vue'
import Btn from '@/components/base/Btn.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Radio from '@/components/form/Radio.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'UsersView',

  components: {
    List,
    Search,
    Pagination,
    Section,
    MiniTag,
    Btn,
    UserID,
    ToolDropdown,
    SelectExtended,
    Radio,
    MenuItem,
    Modal,
  },

  data() {
    return {
      search: {
        attributs: ['nom', 'prenom', 'email'],
        filters: {
          entite: {
            type: 'object',
            key: 'id',
            values: [],
          },
          profil: {
            type: 'object',
            key: 'id',
            values: [],
          },
          actif: {
            type: 'boolean',
            key: null,
            values: null,
            label: 'Actif',
          },
        },
      },
      list: {
        target: {
          route: {
            name: 'user',
            paramsFromRoute: undefined,
            itemParams: { userid: 'id' },
          },
        },
        api: {
          endpoint: 'utilisateur',
          params: {
            sort: 'prenom.ASC,nom.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Prénom,Nom', dataField: 'prenom,nom' },
          {
            label: 'Mail',
            dataField: 'email',
            hidden: 'mobile' /* , grow: true */,
            isLink: true,
          },
          { label: 'Profils de droits', hidden: 'tablet' },
        ],
        col: ['prenom', 'email', 'profils'],
        key: 'id',
      },
      modalData: {},
      modal: {
        deleteItems: false,
        reinitUserPwd: false,
        disableUser: false,
      },
    }
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        this.fetchService.delete(`utilisateur/${item.id}`).then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'L\'utilisateur a été supprimé.',
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )
      })

      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
      this.modal.deleteItems = false
    },
    reinitPwd() {
      this.emitter.emit('open-loader')
      this.fetchService.get(`utilisateur/${this.modalData.id}/mdp`).then(
        (response) => {
          this.emitter.emit('alert', {
            type: 'success',
            content: response.data,
          })
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
          this.emitter.emit('close-loader')
        },
      )
      this.modal.reinitUserPwd = false
    },
    disableUser() {
      this.emitter.emit('open-loader')

      this.modalData.actif = false
      this.modalData.entite_id = this.modalData.entite.id

      this.fetchService
        .put(`utilisateur/${this.modalData.id}`, this.modalData)
        .then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: "L'utilisateur a bien été désactivé.",
            })
            this.emitter.emit('list-refresh')
            this.emitter.emit('close-loader')
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
            this.emitter.emit('close-loader')
          },
        )
      this.modal.disableUser = false
    },
  },
}
</script>
